import React from 'react'

const Location = () => {
  return (
  <div id='location'>
    <div className='location-wrapper'>
      <h2 className="location-header">Kde nás najdete?</h2>
      <div className="map">
      <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Rooseveltova%202,%20412%2001%20Litom%C4%9B%C5%99ice+(MNB%20Barbershop%20Litom%C4%9B%C5%99%C3%ADce)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">area maps</a></iframe>
      </div>
      <div className="contact">
      <span className="span">
        <img src="./imgs/phone.svg" alt="phone" className="phone" />
        <h3 className="phone">+420 720 581 786</h3>
      </span>
      <span className="span">
        <img src="./imgs/mail.svg" alt="mail" className="phone" />
        <h3 className="phone">dotazy.mnb@gmail.com</h3>
      </span>
      </div>
    </div>
  </div>
  )
}

export default Location
