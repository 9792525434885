import React from 'react'
import { useRef } from 'react';
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {

    const navRef = useRef();
    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav")
    } //pokaždé co je zavolaná tato funkce přidáme nebo odebereme class "responsive_nav" (toggle🔼) z classlistu <nav></nav> tagu
  return (
          <header>
            <img src='./imgs/logo.jpg' id='logo' alt='logo'></img>
                <nav ref={navRef}>
                    <a className="menu-item" href='#about'>O nás</a>
                    <a className="menu-item" href='#pricing'>Ceník služeb</a>
                    <a className="menu-item" href='#location'>Kde nás najdete</a>
                    <a className="menu-item" href='#open'>Otevírací doba</a>
                    <button className='nav-btn nav-close-btn' onClick={showNavbar}>
                        <FaTimes/>
                    </button>
                </nav>
                <button className='nav-btn' onClick={showNavbar}>
                    <FaBars/>
                </button>
        </header>
  )
}

export default Navbar
