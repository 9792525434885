import React from 'react'

const Service = (props) => {
  return (
    <div className='service'>
      <h3 className="service-name">{props.card.name}</h3>
      <p className="service-description">{props.card.description}</p>
      <h5 className="service-time">{props.card.time}</h5>
      <h4 className='service-price'>{props.card.price},-</h4>
    </div>
  )
}

export default Service
