import React from 'react'

const Oteviracka = () => {
  return (
  <div id='open'>
    <div className='oteviracka-wrapper'>
      <h2 className="oteviracka-header">Otevírací doba</h2>
      <div className="oteviracka">
        <span className="den-wrapper">
            <h4 className="den">Po</h4>
            <p className='den-cas'>10 - 18</p>
        </span>
        <span className="den-wrapper">
            <h4 className="den">Út</h4>
            <p className='den-cas'>10 - 18</p>
        </span>
        <span className="den-wrapper">
            <h4 className="den">St</h4>
            <p className='den-cas'>10 - 18</p>
        </span>
        <span className="den-wrapper">
            <h4 className="den">Čt</h4>
            <p className='den-cas'>10 - 18</p>
        </span>
        <span className="den-wrapper">
            <h4 className="den">Pá</h4>
            <p className='den-cas'>10 - 18</p>
        </span>
        <span className="den-wrapper">
            <h4 className="den">So</h4>
            <p className='den-cas'>10 - 18</p>
        </span>
        <span className="den-wrapper">
            <h4 className="den">Ne</h4>
            <p className='den-cas'>Zavřeno</p>
        </span>
      </div>
    </div>
  </div>
  )
}

export default Oteviracka
