import React from 'react'

const Gallery = () => {

  return (
    <div className='gallery-wrapper'>
      <h2 className='gallery-header'>Certifikáty</h2>
      <div className="container">
        <div className="image-container">
            <div className="image"><img src="./imgs/certificate1.jpg" alt="certifikát" /></div>
            <div className="image"><img src="./imgs/certificate2.jpg" alt="certifikát" /></div>
            <div className="image"><img src="./imgs/certificate3.jpg" alt="certifikát" /></div>
        </div>
      </div>
    </div>

  )
}

export default Gallery
