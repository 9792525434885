import React from 'react'
import { FaBars, FaTimes } from "react-icons/fa";
import { useRef } from 'react';
import { motion } from 'framer-motion';
import { Parallax } from "react-parallax";
import Background from "../background_darker.png";

const Hero = () => {
  return (
<Parallax id="hero" bgImage={Background} strength={40} >
        <motion.div className="hero-content">
          <h3 className='new-barber'>Hledáme další posilu do našeho Barbershopu. Neváhejte nás kontaktovat na tel.: +420 607 443 824
(praxe v barber shopu nutná)</h3>
            <strong><h1 className='hero-header'>Martin Nguyen</h1></strong>
                <strong><h2 className='hero-header2'>Barbershop Litoměřice</h2></strong>
                <a href='https://booking.reservanto.cz/Modal/?id=15718&lId=19106&lIds=19106' target="_blank"><motion.button className='hero-btn'
                whileTap={{ scale: 1.2 }}
                >Objednat se</motion.button></a>
        </motion.div>
</Parallax>
  )
}

export default Hero
