import React from 'react'

const Est = () => {
  return (    
<div className="est-wrapper">
    <div className="est">
            <p className='est-text'>est. 2019</p>
        </div>
</div>

  )
}

export default Est
