import React from 'react'

const About = () => {
  return (
  <div id="about">
    <div className='about-wrapper'>
      <h2 className='about-header'>O nás</h2>
      <p className="about-text">
      MNB Barbershop je pánské holičství, kde víme, co správný chlap očekává. Fungujeme od roku 2019 v Teplicích a díky naší skvělé práci a naplněnému diáři jsme založili druhou pobočku v Litoměřicích.
      Věříme a doufáme, že jak v Teplicích tak v Litoměřicích budete stejně nebo více spokojení. 
      </p>
      <p className='about-text'>
      Jsme precizní a snažíme se na každém zákazníkovi vykouzlit ten nejlepší střih na míru.
      </p>
      <span className='media'>
        <a href='https://instagram.com/mnb_barbershop_litomerice?igshid=YmMyMTA2M2Y=' target="blank" ><img src="./imgs/instagram.svg" alt="IG" className="insta" /></a>
      </span>
    </div>
  </div>
  )
}

export default About
