import Hero from "./Components/Hero";
import About from "./Components/About";
import Navbar from "./Components/Navbar";
import Est from "./Components/Est";
import Gallery from "./Components/Gallery";
import serviceData from "./serviceData";
import Service from "./Components/Service";
import Location from "./Components/Location";
import Footer from "./Components/Footer";
import Oteviracka from "./Components/Oteviracka";

function App() {
const serviceCards = serviceData.map((card) => {
  return(
    <Service
      key={card.id}
      card={card}
    />
  )
})
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Est />
      <About /> 
      <Gallery />
      <div id="pricing">
        <div className="pricing-wrapper">
          <h2 className="pricing-header">Ceník služeb</h2>
          <div className="pricing-cards">
          <div className='service-card-wrapper'>{serviceCards}</div>
          </div>
        </div>
      </div>
      <Location />
      <Oteviracka />
      <Footer />
    </div>
  );
}

export default App;
