import React from 'react'

const Footer = () => {
const rok = new Date()
let year = rok.getFullYear();
  return (
    <footer>
        <h4 className='footer-text'>{year} MNB Litoměřice</h4>
    </footer>
  )
}

export default Footer
