const serviceData = [
    {
        id: 0,
        name: "Střih vlasů",
        description: "Střih vlasů, zaholení břitvou, úprava obočí, depilace chloupků dle domluvy (uši, nos, mezi obočí), mytí + masáž, styling, ošetření pokožky, profesionální kosmetika, alko/nealko.",
        time: "(cca 40 minut)",
        price: 450
    },{
        id: 1,
        name: "Dětský střih (do 10 let)",
        time: "(cca 30 minut)",
        price: 300
    },{
        id: 2,
        name: "Střih vlasů + vousy",
        description: "Střih, úprava a holení vousů (nůžky, strojek) před holením ,,hot towel‘‘ (horký ručník). Depilace chloupků voskem dle domluvy (uši, nos, mezi obočí), mytí + masáž, ošetření pokožky, profesionální kosmetika, alko/nealko.",
        time: "(cca 60 minut)",
        price: 750
    },{
        id: 3,
        name: "Vousy",
        description: "Úprava vousů (strojek či nůžky). Před holením ,,hot towel‘‘ (horký ručník). Depilace obličeje dle domluvy (uši, nos, mezi obočí). Ošetřeni pokožky (balzám, kolínská). Úprava obočí alko/nealko.",
        time: "(cca 30 minut)",
        price: 350
    },{
        id: 4,
        name: "Peeling",
        description: "Napařovač s teplou párou a ozonem, peeling, masáž obličeje a na závěr ,,hot towel‘‘ (horký ručník), mytí obličeje, ošetření pokožky a profesionální kosmetika, alko/nealko",
        time: "cca 15 minut",
        price: 200
    },{
        id: 5,
        name: "Komplet péče",
        description: "Střih vlasů, úprava vousů (nůžky, strojek) před holením ,,hot towel‘‘ (horký ručník), napaření obličeje teplou párou a ozonem, peeling a masáž, depilace chloupků dle domluvy (uši, nos, mezi obočí), úprava obočí, profesionální kosmetika, alko/nealko.",
        time: "(cca 75 minut)",
        price: 950
    },{
        id: 6,
        name: "Komplet péče + barvení vousů",
        description: "Střih vlasů, úprava vousů (nůžky, strojek) před holením ,,hot towel‘‘ (horký ručník), napaření obličeje teplou párou a ozonem, peeling a masáž, depilace chloupků dle domluvy (uši, nos, mezi obočí), úprava obočí, přirozené dobarvení vousů, ošetření pokožky, profesionální kosmetika, alko/nealko.",
        price: 1150
    },{
        id: 7,
        name: "Přirozené dobarvení vousů",
        description: "alko/nealko",
        time: "(cca 15 min)",
        price: 200
    }
]

export default serviceData